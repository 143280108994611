.about {
  padding: 80px 0;
  text-align: center;
}

.about-content {
  max-width: 90%;
  margin: 0 auto;
}

.title {
  font-size: 36px;
  font-weight: bold;
  margin-top: 5rem;
  color: white;
  letter-spacing: 4px;
}



.characters {
  margin-bottom: 20px;
}

.characters img {
  max-width: 100%;
}

.description {
  font-size: 16px;
  color: white;
  line-height: 1.6;
  margin-bottom: 20px;
}

.features {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 20rem;
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
}

.feature img {
  width: 150px;

  margin-bottom: 10px;
}

.feature span {
  font-size: 16px;
}